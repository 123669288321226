// Styles file for `fedbackedprograms.com`.

// Styles written here will override the corresponding component
// This is a stylesheet example of `Harpquiz`.

import { css, keyframes } from 'styled-components';
import images from './images';

const FadeIn = keyframes`
  0% {opacity: 0;}
  75% {opacity: 0;}
  100% {opacity: 1;}
`;

// Theming
const DEFAULT_COLORS = {
  questionTitle: '#000',
  text: '#000',
  headline: '#000',
  link: '#183854',
  submitButtonText: '#fff',
  choiceInput: '',
  choiceInputHover: '',
  submitButtonBackground: '#5cbe5c',
  headerBackground: '',
  mainBackground: '#e4f0f4',
  surveyBackground: '#e4f0f4',
  footerBackground: '#e4f0f4',
  progressBarBackground: '',
  progressBarFill: '',
  sliderFill: '#224169',
};

const DEFAULT_FONT = {
  questionTitle: '22px',
  text: '16px',
  headline: '28px',
  submitButton: '30px',
  tcpa: '11px',
  footerText: '12px',
};

const DEFAULT_SIZE = {
  headerHeight: '92px',
  headerMobileHeight: '',
  submitButtonWidth: '200px',
  inputFieldHeight: '44px',
  inputFieldWidth: '100%',
  inputFieldMobileWidth: '',
};

// Header
const HeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto; /* Don't forget to change the min-height calculation for SurveyBackground */
  width: 100vw;
  padding: 15px 20px;
  margin: 0 auto;
  background: ${DEFAULT_COLORS.headerBackground};
  max-width: 960px;

  #global-seal {
    width: 100%;
    max-width: 110px;
  }
`;

const HeaderImage = css`
  height: auto;
  width: 100%;
  max-width: 280px;
  max-height: 100%;
  margin-bottom: 0;
  @media (max-width: 801px) {
    width: 90%;
  }
`;

// Only used when sites background color is needed
// specificy "background-color" because of the blurred-rates image.
const AppWrapper = css`
  background-color: #e4f0f4;
`;

// Main Background
const SurveyBackground = css`
  padding: 2vh 0;
  min-height: calc(100vh - 75px);
  max-width: 960px;
  width: 100vw;
  margin: auto;

  background-color: #e4f0f4;
`;

const SurveyContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;

  @media (max-width: 840px) {
    margin: auto;
  }
`;

const QuestionNumber = css`
  position: absolute;
  top: 5px;
  left: 5px;
  color: #000;
`;

const FormContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0;
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 10px #787878;

  @media (max-width: 800px) {
    width: calc(100% - 20px);
    margin: auto;
  }
`;

const GlobalSealImg = css`
  margin: 0;
  max-width: 110px;
  .harpQuiz & {
    margin-top: 16px;
  }
`;

// Containers
// Multiple choice
const ChoiceContainer = css`
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin: 10px 0;
  transition: color 230ms ease;
  width: ${DEFAULT_SIZE.inputFieldWidth};
  height: auto;
  background: ${DEFAULT_COLORS.choiceInput};
  color: ${DEFAULT_COLORS.text};
  font-weight: 600;

  &:active {
    opacity: 0.9;
    background: ${DEFAULT_COLORS.choiceInputHover};
    color: #224169;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.9;
    background: ${DEFAULT_COLORS.choiceInputHover};
    color: #224169;
    @media (max-width: 801px) {
      background: ${DEFAULT_COLORS.choiceInput};
      color: ${DEFAULT_COLORS.text};
    }
  }
`;

// Styles for selected radio button (multiple-choice)
const InputContainer = css`
  width: 100%;
  > input + label {
    background: transparent url(${images.Unselected}) 0 50%/20px no-repeat;
    span {
      font-size: 1em;
      @media (max-width: 801px){
        font-size: .7em;
      }
    }
    pre{
      margin: 0;
      font-size: 1em;
    }
  }
  > input:checked + label {
    background: transparent url(${images.Selected}) 0 50%/20px no-repeat;
    /* background: url(${images.Checkmark}) 94% 50%/20px no-repeat
      ${DEFAULT_COLORS.choiceInputHover}; */
  }
  > input[id='excellent'] + label {
    width: 60%;
    height: 38px;
    margin: 2px 0;
    background: url(${images.Unselected}) 0 50%/20px no-repeat, url(${images.Excellent}) 95% 50%/30px no-repeat;
    @media (max-width: 801px){
      width: 100%;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: url(${images.Unselected}) 0 50%/20px no-repeat
    }
  }
  > input[id='good'] + label {
    width: 60%;
    height: 38px;
    margin: 2px 0;
    background: url(${images.Unselected}) 0 50%/20px no-repeat, url(${images.Good}) 95% 50%/30px no-repeat;
    @media (max-width: 801px){
      width: 100%;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: url(${images.Unselected}) 0 50%/20px no-repeat
    }
  }
  > input[id='average'] + label {
    width: 60%;
    height: 38px;
    margin: 2px 0;
    background: url(${images.Unselected}) 0 50%/20px no-repeat, url(${images.Average}) 95% 50%/30px no-repeat;
    @media (max-width: 801px){
      width: 100%;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: url(${images.Unselected}) 0 50%/20px no-repeat
    }
  }
  > input[id='fair'] + label {
    width: 60%;
    height: 38px;
    margin: 2px 0;
    background: url(${images.Unselected}) 0 50%/20px no-repeat, url(${images.Fair}) 95% 50%/30px no-repeat;
    @media (max-width: 801px){
      width: 100%;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: url(${images.Unselected}) 0 50%/20px no-repeat
    }
  }
  > input[id='poor'] + label {
    width: 60%;
    height: 38px;
    margin: 2px 0;
    background: url(${images.Unselected}) 0 50%/20px no-repeat, url(${images.Poor}) 95% 50%/30px no-repeat;
    @media (max-width: 801px){
      width: 100%;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: url(${images.Unselected}) 0 50%/20px no-repeat
    }
  }
  > input[id='excellent']:checked + label {
    background: url(${images.Selected}) 0 50%/20px no-repeat, url(${images.Excellent}) 95% 50%/30px no-repeat;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: url(${images.Selected}) 0 50%/20px no-repeat
    }
  }
  > input[id='good']:checked + label {
    background: url(${images.Selected}) 0 50%/20px no-repeat, url(${images.Good}) 95% 50%/30px no-repeat;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: url(${images.Selected}) 0 50%/20px no-repeat
    }
  }
  > input[id='average']:checked + label {
    background: url(${images.Selected}) 0 50%/20px no-repeat, url(${images.Average}) 95% 50%/30px no-repeat;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: url(${images.Selected}) 0 50%/20px no-repeat
    }
  }
  > input[id='fair']:checked + label {
    background: url(${images.Selected}) 0 50%/20px no-repeat, url(${images.Fair}) 95% 50%/30px no-repeat;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: url(${images.Selected}) 0 50%/20px no-repeat
    }
  }
  > input[id='poor']:checked + label {
    background: url(${images.Selected}) 0 50%/20px no-repeat, url(${images.Poor}) 95% 50%/30px no-repeat;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: url(${images.Selected}) 0 50%/20px no-repeat
    }
  }
`;

// Input
const inputStyle = css`
  width: ${DEFAULT_SIZE.inputFieldWidth};
  height: ${DEFAULT_SIZE.inputFieldHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border-radius: 5px;
  font-size: 16px;
  line-height: 1;
  margin-top: 0;
  background: #fff;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #bbb'} !important; /* overrides initial setting*/
  ::-webkit-input-placeholder {
    text-align: center;
  }
  text-align: center;
`;

const InputField = css`
  ${inputStyle}
  border: ${props => props.border};
  width: 100%;
`;

const StyledTextArea = css`
  ${inputStyle}
  border-radius: 5px;
  border: ${props => props.border};
`;

const AddressContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  &#email-container {
    display: block;
  }
  .emailLabel {
    margin: 20px 0 5px;
  }
  #zip-input {
    margin-top: 10px;
  }
`;

// Dropdown
const StyledSelect = css`
  ${inputStyle}
  padding: 0 0 0 15px;
  -webkit-appearance: none;
  background: url(${images.Dropdown}) 98% 50%/16px no-repeat #fff;
  text-align: left;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
`;

// Zip input
const StyledZip = css`
  ${inputStyle} ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FinalInputContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;

  > input,
  select {
    margin-top: 0;
    margin-bottom: 5px;
  }
`;

// Hidden inputs
const HiddenContainer = css`
  display: flex;
  flex-direction: column;
`;

// Survey styles
const QuestionTitle = css`
  color: ${DEFAULT_COLORS.questionTitle};
  font-weight: 700;
  font-size: ${DEFAULT_FONT.questionTitle};
  line-height: 1.18;
  margin: 5px 0;
  &#questionTitle-zipcode,
  &#questionTitle-email,
  &#questionTitle-current_lender,
  &#questionTitle-additional_cash_needed {
    display: none;
  }
  @media (max-width: 801px) {
    font-size: 20px;
  }
  &#questionTitle-opt_served_military {
    letter-spacing: 0.3px;
  }
`;

const InputTitle = css`
  margin-bottom: 0;
  margin-top: 10px;
`;

const Subtitle = css`
  width: 100%;
  font-family: 'Montserrat', Helvetica, Arial, san-serif;
  margin-bottom: 0;
`;

const TipContainer = css`
  text-align: center;
  background: #fff;
  padding: 10px;
  color: #7d7d7d;
  border-radius: 3px;
  width: 80%;
`;

const QuestionContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: auto;
  #email-container & {
    display: block !important;
  }
`;

const QuestionBodyContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;
`;

// Slider
const SliderBox = css`
  width: 90%;
  margin: auto;
`;
const StyledSlider = css`
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  margin: auto;
`;

const StyledThumb = css`
  width: 26px;
  height: 26px;
  border: 1px solid #333;
  border-radius: 50%;
  z-index: 3;
  background: #333 url(${images.SliderArrows}) 50% 50%/15px no-repeat;
  outline: 0;
  margin-top: -8px;
  cursor: pointer;
`;

const DisplayNumber = css`
  font-size: ${props => (props.primary ? '19px' : '16px')};
  font-weight: 500;
  margin: ${props => (props.primary ? '20px auto 25px' : '10px 0 20px')};
  padding: 0;
  color: #000000;
  text-align: center;
`;

const NumberContainer = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0;
  p {
    font-weight: 500;
  }
`;

const SliderContainer = css`
  width: inherit;
  height: 10px;
  border-radius: 8px;
  border: 1px solid #bdc3c7;
  margin-top: 0;
`;

const FillContainer = css`
  margin-top: 0;
  position: absolute;
  height: 10px;
  border-radius: 8px 0 0 8px;
  background: ${DEFAULT_COLORS.sliderFill};
  width: ${props => `${props.width}%`};
`;

// ProgressBar
const ProgressContainer = css`
  margin: 0 auto 2vh auto;
  width: 50vw;
  max-width: 400px;
  height: 2.5vh;
  background: ${DEFAULT_COLORS.progressBarBackground};
  border-radius: 5px;
  overflow: hidden;

  @media (max-width: 840px) {
    width: 75vw;
    height: 2vh;
  }
`;

// Progressbar animation
const ProgressBarStripes = keyframes`
  from {
    background-position: 50px 0;
  } to {
    background-position: 0 0;
  }
`;

const StatusBar = css`
  animation: ${ProgressBarStripes} 2s linear infinite;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
  width: ${props => props.width};
  height: inherit;
  background-color: ${DEFAULT_COLORS.progressBarFill};
  transition: width 0.25s ease;
`;

// buttons
const SubmitButton = css`
  margin: 0;
  width: ${DEFAULT_SIZE.submitButtonWidth};
  padding: 7px 5px;
  border-radius: 3px;
  background-color: ${props =>
    props.color || DEFAULT_COLORS.submitButtonBackground};
  border: 0px;
  font-size: ${DEFAULT_FONT.submitButton};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${DEFAULT_COLORS.submitButtonText};
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: #6ec56e;
  }

  /* Final Submit button is different than the 'next' buttons */
  &#submit-button {
    width: 300px;
    margin: 0 auto;
  }
`;

const BackButton = css`
  border: none;
  background: inherit;
  color: #777;
  margin: 0;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: 801px) {
    &.last-page-previous-button {
      display: none;
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background: none;
  }
`;

const ButtonContainer = css`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
`;

const BackButtonSpacer = css`
  height: 0;
`;

// Footer
const FooterContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 20px;
  background: #e4f0f4;
  max-width: 960px;
  width: 100%;
  margin: auto;

  p {
    margin-bottom: 0;
  }

  a {
    color: ${DEFAULT_COLORS.link};
  }
`;

const DisclosureContainer = css`
  text-align: center;
  width: calc(100% - 20px);
  max-width: 960px;
  margin: 25px auto;

  a {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 0.8em;
  }
`;

const ModalButtonContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  margin: 0 auto;
  border-top: 1px solid #c9c9c9;
  padding-top: 25px;

  span {
    color: ${DEFAULT_COLORS.link};
  }

  @media (max-width: 500px) {
    width: 98%;
  }
`;

const ModalButton = css`
  border: 0;
  background: inherit;
  color: ${DEFAULT_COLORS.link};
  cursor: pointer;
  font-size: 13px;

  &:hover {
    text-decoration: underline;
  }
`;

// Modal
const ModalBackground = css`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;
const ModalContainer = css`
  margin: auto;
  background: white;
  width: ${props => (props.primary ? '50vw' : '70vw')};
  height: ${props => (props.primary ? '60vh' : '80vh')};
  padding: 3vh 3vw;
  border-radius: 10px;
  overflow: auto;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ExitButton = css`
  top: ${props => (props.primary ? '19vh' : '9vh')};
  right: ${props => (props.primary ? '24vw' : '14vw')};
  position: absolute;
  border-radius: 50%;
  border: 1px solid #eee;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.5s ease;
  height: 33px;
  width: 33px;
  background: #fff;

  &:hover {
    background: #969696;
    color: #fff;
  }

  @media (max-width: 900px) {
    right: 3vw;
  }
`;

const ModalInformation = css`
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  line-height: 1.5em;

  br {
    line-height: 2em;
  }

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

const BoldText = css`
  font-size: 0.75em;
  color: ${props => props.primary && '#C1794C'};
  text-align: ${props => props.primary && 'center'};
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const Text = css`
  font-size: 16px;
  font-weight: ${props => props.primary && '700'};
  margin-bottom: 10px;
`;

const FooterText = css`
  margin-bottom: 10px;
  white-space: pre-line;
  text-align: center;
  font-size: ${DEFAULT_FONT.footerText};
  color: ${({ primary }) => primary && '#767676'};
`;

const ImageList = css`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const HeadlineContainer = css`
  text-align: center;
  margin-bottom: 40px;
  // animation: ${FadeIn} 2s;
`;

const HeadlineHeader = css`
  color: ${DEFAULT_COLORS.headline};
  font-size: clamp(22px, 4vw, 36px);
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.1;

  @media (max-width: 800px) {
    font-weight: 400;
  }
`;

const SubHeader = css`
  font-family: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, Arial, sans-serif;
  font-size: ${DEFAULT_FONT.text};
  font-weight: 400;
  color: ${DEFAULT_COLORS.headline};
  margin-bottom: 0;
  margin-top: 10px;
`;

const SmallText = css`
  font-size: ${props => (props.secondary ? '0.9em' : '0.7em')};
  font-weight: ${props => (props.primary ? '700' : '200')};
  max-width: 500px;
  margin: auto;
`;

const ErrorText = css`
  color: #ee0000;
  text-align: center;
  font-size: 0.9em;
  margin: 5px 0 0;
  width: 100%;
`;

const FinalDisclosureContainer = css`
  padding: 0;
  min-height: 20px;
  max-width: 800px;
  margin: 20px auto 0 auto;
  background: #fff !important;
  width: 100%;

  p {
    margin-bottom: 2px;
    font-size: ${DEFAULT_FONT.tcpa};
    color: #767676;
  }
`;

// Loading Spinner
const Bounce = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

const SpinnerContainer = css`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
`;

const Spinner = css`
  width: 18px;
  height: 18px;
  background-color: #5598c1;
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1.4s infinite ease-in-out both;
  animation-delay: ${props => props.delay};
`;

const LoadingContainer = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;

const SpinnerMainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 40px;
  background: #fff;
`;

const CongratsHolder = css`
  /* Style property not used in this survey */
`;

const CheckboxWrapper = css`
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
`;

const CheckboxContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
`;

const CheckboxLabel = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > input {
    -webkit-appearance: none;
    height: 0;
    width: 0;
    margin-left: 5px;
  }

  > input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    left: 0;
    border: 1px solid #dedede;
    border-radius: 3px;
    background-color: white;
    box-shadow: inset #777 0 0 2px;
    margin: 4px;
  }
  > input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 6px;
    height: 12px;
    border: solid grey;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 9px;
    margin: 4px;
  }

  @media (max-width: 500px) {
    font-size: 0.8em;
  }
`;

const CheckboxParagraph = css`
  margin-left: 40px;
  margin-bottom: 0;
  font-size: 0.9em;
  text-align: left;
  font-style: italic;
`;

// Change Address container on final-frame step
const ChangeAddress = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;

  p {
    margin-bottom: 0;
  }
  button {
    color: #00e;
    text-decoration: underline;
    margin: 0;
  }
`;

const AddressSubtext = css`
  margin-left: 30px;
`;

const LenderDropdown = css`
  background: #fff;
  margin-bottom: 10px;
  padding: 0 10px;
  position: absolute;
  width: 100%;

  p {
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    &:hover,
    &:focus {
      background: ${DEFAULT_COLORS.link};
      color: #fff;
      cursor: pointer;
    }
  }
`;
export default {
  HeaderContainer,
  HeaderImage,
  AppWrapper,
  SurveyBackground,
  SurveyContainer,
  QuestionNumber,
  FormContainer,
  GlobalSealImg,
  ChoiceContainer,
  InputContainer,
  inputStyle,
  InputField,
  StyledTextArea,
  AddressContainer,
  StyledSelect,
  StyledZip,
  FinalInputContainer,
  HiddenContainer,
  QuestionTitle,
  InputTitle,
  Subtitle,
  TipContainer,
  QuestionContainer,
  QuestionBodyContainer,
  StyledSlider,
  StyledThumb,
  DisplayNumber,
  NumberContainer,
  SliderContainer,
  SliderBox,
  FillContainer,
  ProgressContainer,
  StatusBar,
  SubmitButton,
  BackButton,
  ButtonContainer,
  BackButtonSpacer,
  FooterContainer,
  DisclosureContainer,
  ModalButtonContainer,
  ModalButton,
  ModalBackground,
  ModalContainer,
  ExitButton,
  ModalInformation,
  BoldText,
  Text,
  FooterText,
  ImageList,
  HeadlineContainer,
  HeadlineHeader,
  SubHeader,
  SmallText,
  ErrorText,
  FinalDisclosureContainer,
  SpinnerContainer,
  Spinner,
  LoadingContainer,
  SpinnerMainContainer,
  CongratsHolder,
  CheckboxWrapper,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxParagraph,
  ChangeAddress,
  AddressSubtext,
  LenderDropdown,
};
